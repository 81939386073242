import React from 'react'
import Link from 'gatsby-link'
import solutionsSalesforce from "../images/index/solutions_salesforce.svg";
import solutionsRestaurant from "../images/index/solutions_restaurant.svg";

const Solucoes = () => (

  <div>
    <div className="row">
      <div className="col-md-6" data-vertical_center="true">
        <h5 className="text-blue-sc">
          Força de Vendas
        </h5>
        <p className="paragraph">
          Soluções voltadas a aumento de vendas do seu negócio.
        </p>
        <div className="ui-accordion-panel ui-green-sc">
          <div className="ui-card shadow-sm ui-accordion">
            <h6 className="toggle" data-toggle="accordion-one">Portal do Fornecedor</h6>
            <div className="body in" data-accord="accordion-one">
              <p>
                Ofereça aos seus fornecedores um portal para realização de pedidos.
                Nele o administrador pode cadastrar representantes, produtos, lista de preço,
                visualizar e editar as vendas, etc.
                Já os fornecedores podem ver suas estatísticas, cadastrar seus clientes e realizar pedidos para seus clientes.
                Isso tudo podendo ser integrado ao seu ERP.
              </p>
              <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
            </div>
          </div>
          <div className="ui-card shadow-sm ui-accordion">
            <h6 className="toggle" data-toggle="accordion-two">Aplicativo do Fornecedor</h6>
            <div className="body" data-accord="accordion-two">
              <p>
                Mobilidade e praticidade para seu fornecedor gerenciar clientes e criar pedidos.
                O aplicativo permite sincronização de clientes, pedidos e preços.
                Você ainda pode captar aonde o pedido foi realizado através do GPS do celular.
                Ele pode funcionar em conjunto com o Portal. Disponível em iOS e Android.
              </p>
              <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
            </div>
          </div>
          <div className="ui-card shadow-sm ui-accordion">
            <h6 className="toggle" data-toggle="accordion-three">Aplicativo E-commerce</h6>
            <div className="body" data-accord="accordion-three">
              <p>
                Quer aumentar as vendas no seu e-commerce?
                Ofereça um aplicativo exclusivo e ainda integrado a sua plataforma de e-commerce.
                Disponível em iOS e Android.
              </p>
              <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <img src={solutionsSalesforce} alt="System Code"
             data-uhd data-max_width="1000" className="responsive-on-lg" />
      </div>
    </div>
    <div className="row">
      <div className="col-md-6" data-vertical_center="true">
        <img src={solutionsRestaurant} alt="System Code"
             data-uhd data-max_width="1000" className="responsive-on-lg" />
      </div>
      <div className="col-md-6">
        <h5 className="text-blue-sc">
          Para Restaurantes
        </h5>
        <p className="paragraph">
          Sofisticação para seu estabelecimento com soluções exclusivas para restaurantes, bistros, docerias, bares...
        </p>
        <div className="ui-accordion-panel ui-green-sc">
          <div className="ui-card shadow-sm ui-accordion">
            <h6 className="toggle" data-toggle="accordion-four">Aplicativo Quero Pedir</h6>
            <div className="body" data-accord="accordion-four">
              <p>
                Muito mais que um delivery, ofereça aos seus clientes pedidos no estabelecimento, para retirar ou delivery...
                Aumente suas vendas e agilize o atendimento. Disponível para iOS e Android.
              </p>
              <a href="https://queropedir.com/" target="_BLANK" className="btn-link btn-arrow" target="_blank">Conheça o Projeto</a>
            </div>
          </div>
          <div className="ui-card shadow-sm ui-accordion">
            <h6 className="toggle" data-toggle="accordion-five">Aplicativo Exclusivo</h6>
            <div className="body in" data-accord="accordion-five">
              <p>
                Ofereça aos seus clientes um aplicativo com a identidade visual de seus estabelecimento,
                Os clientes poderão realizar pedidos no estabelecimento, para retirar ou delivery.
                Aumente suas vendas, agilize o atendimento, divulgue eventos e ainda ofereça promoções exclusivas e segmentadas.
                Disponível em iOS e Android.
              </p>
              <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
            </div>
          </div>
          <div className="ui-card shadow-sm ui-accordion">
            <h6 className="toggle" data-toggle="accordion-six">Tablet do Estabelecimento</h6>
            <div className="body in" data-accord="accordion-six">
              <p>
                Ofereça aos seus clientes um tablet interativo para realização de pedidos, divulgação de eventos,
                interação com o garçom, pedido de contas.
                Aumente suas vendas e agilize o atendimento. Disponível para tablets Android.
              </p>
              <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
            </div>
          </div>
          <div className="ui-card shadow-sm ui-accordion">
            <h6 className="toggle" data-toggle="accordion-seven">Aplicativo do Garçom</h6>
            <div className="body" data-accord="accordion-seven">
              <p>
                Não perca seus pedidos e agilize o atendimento. Forneça um aplicativo de celular para seus garçons
                enviar os pedidos para a cozinha/barman direto da mesa. O aplicativo ainda conta com pesquisa de satisfação.
              </p>
              <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
            </div>
          </div>
          <div className="ui-card shadow-sm ui-accordion">
            <h6 className="toggle" data-toggle="accordion-eight">Software de Gestão</h6>
            <div className="body" data-accord="accordion-eight">
              <p>
                Gerencie seu restaurante e todo nosso ecossistema com o nosso Software de Gestão exclusivo para restaurantes.
              </p>
              <Link to="contato" className="btn-link btn-arrow">Entre em Contato</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

);

export default Solucoes
